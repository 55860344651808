import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import useDarkTheme from "./hooks/useDarkMode";

import Profile from "./pages/Profile";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ResetPassword from "./pages/ResetPassword";
import SubCategories from "./pages/SubCategories";
import { CategoryGuide } from "./pages/CategoryGuide";
import { CategoryProgramChapters } from "./pages/CategoryProgramChapters";
import { EverwellTutorials } from "./pages/EverwellTutorials";
import ForgetPassword from "./pages/ForgetPassword";
import Toaster from "./components/Toaster";
import EditBookmarks from "./pages/EditBookmarks";
import FolderBookmarks from "./pages/FolderBookmarks";
import Annotation from "./pages/Annotation";

import Layout from "./components/Layout";


import "./App.css";


function App() {
  const { user } = useSelector((state) => state.app);
  const [colorTheme, setTheme] = useDarkTheme();

  return (
    <div className="App">
      <Toaster />

      <Routes>
        {user ? (
          <>
            <Route path="/" element={<Layout colorTheme={colorTheme} setTheme={setTheme} />}>
              <Route index element={<Home />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/category/:type" element={<SubCategories />} />
              <Route path="/category-modules/:type/:categoryId" element={<CategoryGuide />} />
              <Route path="/category-guide/:assetType/chapters/:type" element={<CategoryProgramChapters />} />
              <Route path="/password-reset" element={<ResetPassword />} />
              <Route path="/edit-bookmarks" element={<EditBookmarks />} />
              <Route path="/folder-bookmarks/:folderId" element={<FolderBookmarks />} />
              <Route path="/everwell-tutorials" element={<EverwellTutorials />} />
            </Route>
            <Route path="/annotation" element={<Annotation />} />

          </>
        ) : (
          <>
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgetPassword />} />
          </>
        )}
        <Route path="*" element={<Navigate to={"/"} replace />} />
      </Routes>
    </div>
  );
}

export default App;
