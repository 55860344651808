import React, { memo, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { FaAngleDown } from "react-icons/fa6";
import { Menu, MenuItem } from "@mui/material";
import { Avatar, Divider } from "@mui/material";

import QuestionMark from "./QuestionMark";
import { VideoModal } from "./VideoModal";
import Button from "./Button";

import { getGeneralAssetVideo } from "../helpers/api";

import { Logout } from "../services/authService";

const Navbar = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.app);

    const [videoList, setVideoList] = useState([]);
    const [modalTitle, setModalTitle] = useState('');

    const [isVideoModal, setVideoModal] = useState(false);
    const [isShowVideo, setShowVideo] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleGeneralVideo = () => {
        setModalTitle('General Tutorial');
        // Example static video data
        const videoArray = [
            { id: 1, title: "Video 1", video: "https://s3.amazonaws.com/vhs_web/assets/Everwell_tutorials/1-Understanding_the_Home_Page.mp4" },
            { id: 2, title: "Video 2", video: "https://s3.amazonaws.com/vhs_web/assets/Everwell_tutorials/2-Selecting_Categories.mp4" },
            { id: 3, title: "Video 3", video: "https://s3.amazonaws.com/vhs_web/assets/Everwell_tutorials/3-Types_of_Assets.mp4" },
            { id: 4, title: "Video 4", video: "https://s3.amazonaws.com/vhs_web/assets/Everwell_tutorials/4-Accessing_Content_within_a_Category.mp4" },
            { id: 5, title: "Video 5", video: "https://s3.amazonaws.com/vhs_web/assets/Everwell_tutorials/5-Viewing_Animations.mp4" },
            { id: 6, title: "Video 6", video: "https://s3.amazonaws.com/vhs_web/assets/Everwell_tutorials/6-Viewing_Programs.mp4" },
            { id: 7, title: "Video 7", video: "https://s3.amazonaws.com/vhs_web/assets/Everwell_tutorials/7-Emailing_Animations_and_Programs.mp4" },
            { id: 8, title: "Video 8", video: "https://s3.amazonaws.com/vhs_web/assets/Everwell_tutorials/8-Bookmarking_Assets.mp4" },
            { id: 9, title: "Video 9", video: "https://s3.amazonaws.com/vhs_web/assets/Everwell_tutorials/9-Using_3D_Models_and_Illustrations_%28on_a_computer%29.mp4" },
            // Add more video objects as needed
        ];
        setVideoList(videoArray);
        setVideoModal(true);
        setShowVideo(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <div className="flex justify-between bg-white px-8 py-4 mb-4 border-b">
            <div className="flex items-center">
                <Link to="/">
                    <img src="/assets/logo.png" alt="logo" className="h-16 object-contain" />
                </Link>
            </div>

            {isVideoModal &&
                <VideoModal
                    setVideoModal={setVideoModal}
                    isVideoModal={isVideoModal}
                    videoList={videoList}
                    currentVideo={0}
                    showVideoList={isShowVideo}
                    title={modalTitle} />}

            <div className="flex items-center gap-x-2">
                <div>
                    <Link  styles="text-[#38A3BD] font-semibold !p-2"  to='/everwell-tutorials'>
                        Tutorials
                    </Link>
                </div>
                <div
                    className="inline-block w-[1px] h-6 bg-gray-200 opacity-100 dark:opacity-50"></div>

                <div className="z-20">
                    <QuestionMark />
                </div>

                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem><Link className="w-full font-medium pe-8 my-1" to='/password-reset'>Change Password</Link></MenuItem>

                    <Divider />

                    <MenuItem onClick={() => Logout(dispatch)}><span className="text-red-500 font-semibold text-sm pe-8">Logout</span></MenuItem>
                </Menu>

                <div
                    className="inline-block w-[1px] h-6 bg-gray-200 opacity-100 dark:opacity-50"></div>

                <div className="ml-2 flex items-center gap-3">
                    <Link to='/profile'>
                        <Avatar alt={user?.name} src={user?.avatar ? `${process.env.REACT_APP_URL}${user?.avatar}` : '/static/images/avatar/1.jpg'} />
                    </Link>

                    <FaAngleDown size={20} className="bg-gray-200 p-1 rounded cursor-pointer" onClick={handleClick} />
                </div>
            </div>
        </div>
    );
};

export default memo(Navbar);
