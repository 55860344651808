import React from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { Link } from "react-router-dom";
import { toast } from 'react-toastify';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { login, start, stop } from "../features/appSlice";

import Spinner from "../components/Spinner";
import QuestionMark from "../components/QuestionMark";

const SignInSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('The email field is required'),
    password: Yup.string()
        .min(8, 'Password should be 8 characters long...')
        .required('The password field is required'),
});

const Login = () => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.app);

    const handleSubmit = async (values) => {
        const data = {
            email: values.email,
            password: values.password
        };
        try {

            dispatch(start())

            const res = await axios.post(
                `${process.env.REACT_APP_URL}/api/login`,
                data
            );
            if (res?.data?.token) {
                dispatch(
                    login({
                        user: res?.data?.user,
                        token: res?.data?.token,
                    })
                );
                localStorage.setItem("access_token", res?.data?.token);
            }
            dispatch(stop());
        } catch (error) {
            dispatch(stop());
            toast.error(error?.response?.data?.error || JSON.stringify(error));
        }
    };

    return (
        <div className="background min-h-screen flex flex-col justify-between">
            <div className="container mx-auto flex justify-between items-center">
                <img src="/assets/everwell_logo_main_tagless.png" alt="logo" className="h-20 w-20 object-contain" />
                <QuestionMark />
            </div>
            <div className="flex justify-center items-center">
                <div className="flex flex-col items-center">
                    <img src="/assets/logo.png" alt="logo" className="h-20 w-20 object-contain" />

                    <div className="w-[80vw] md:w-[40vw] bg-blue p-10 flex flex-col gap-y-5 rounded-3xl">
                        <div className="flex flex-col space-y-2 text-center">
                            <h1 className="text-2xl text-white">
                                Login
                            </h1>
                        </div>
                        <Formik
                            initialValues={{
                                email: '',
                                password: '',
                            }}
                            validationSchema={SignInSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ errors, touched }) => (
                                <Form className="space-y-3">
                                    <div className="flex flex-col items-start">
                                        <Field name="email" type="email" className={`p-2 w-full rounded-xl ${errors.email && touched.email ? 'border-red-600' : "border-slate-400"}`} placeholder="Email" />
                                        {errors.email && touched.email ?
                                            <div className="text-red-500 text-sm">{errors.email}</div> : null}
                                    </div>
                                    <div className="flex flex-col items-start">
                                        <Field name="password" type="password" className={`p-2 w-full rounded-xl ${errors.password && touched.password ? 'border-red-600' : "border-slate-400"}`} placeholder="Password" />
                                        {errors.password && touched.password ?
                                            <div className="text-red-500 text-sm">{errors.password}</div> : null}
                                    </div>
                                    <button className='w-full bg-yellow dark:bg-slate-950 p-3 text-white rounded-xl font-bold' disabled={isLoading} type="submit">Sign In</button>
                                    {isLoading && <Spinner />}
                                </Form>
                            )}
                        </Formik>
                        <Link to="/forgot-password" className="px-8 text-center text-sm text-muted-foreground text-white">
                            Forgot your <span className="font-bold">Password?</span>
                        </Link>
                    </div>
                </div>
            </div>
            <div>© Copyright 2023 MediVista Media, LLC. All Rights Reserved.</div>
        </div>
    );
};

export default Login;
