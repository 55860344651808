import React, { useCallback, useEffect, useState } from "react";
import {Slide} from "@mui/material";
import {Link} from "react-router-dom";
import {FaAngleRight} from "react-icons/fa6";
import {LazyLoadImage} from "react-lazy-load-image-component";
import ReactPlayer from "react-player/lazy";
import {FaPlayCircle} from "react-icons/fa";
import {FiPlayCircle} from "react-icons/fi";


export const EverwellTutorials  = () => {
    const videoArray = [
        { id: 1, title: "Understanding the Home Page", duration:'0:19', video: "https://s3.amazonaws.com/vhs_web/assets/Everwell_tutorials/1-Understanding_the_Home_Page.mp4" },
        { id: 2, title: "Selecting Categories", duration:'0:13',  video: "https://s3.amazonaws.com/vhs_web/assets/Everwell_tutorials/2-Selecting_Categories.mp4" },
        { id: 3, title: "Types of Assets", duration:'0:28',  video: "https://s3.amazonaws.com/vhs_web/assets/Everwell_tutorials/3-Types_of_Assets.mp4" },
        { id: 4, title: "Accessing Content within a Category", duration:'0:17',  video: "https://s3.amazonaws.com/vhs_web/assets/Everwell_tutorials/4-Accessing_Content_within_a_Category.mp4" },
        { id: 5, title: "Viewing Animations", duration:'0:10',  video: "https://s3.amazonaws.com/vhs_web/assets/Everwell_tutorials/5-Viewing_Animations.mp4" },
        { id: 6, title: "Viewing Programs", duration:'0:21',  video: "https://s3.amazonaws.com/vhs_web/assets/Everwell_tutorials/6-Viewing_Programs.mp4" },
        { id: 7, title: "Emailing Animations and Programs", duration:'0:14',  video: "https://s3.amazonaws.com/vhs_web/assets/Everwell_tutorials/7-Emailing_Animations_and_Programs.mp4" },
        { id: 8, title: "Bookmarking Assets", duration:'0:45',  video: "https://s3.amazonaws.com/vhs_web/assets/Everwell_tutorials/8-Bookmarking_Assets.mp4" },
        { id: 9, title: "Using 3D Models and Illustration", duration:'1:42',  video: "https://s3.amazonaws.com/vhs_web/assets/Everwell_tutorials/9-Using_3D_Models_and_Illustrations_%28on_a_computer%29.mp4" },
        // Add more video objects as needed
    ];
    const [currentVideo, setCurrentVideo] = useState({});
    const[ everwellTutorials, setEverwellTutorials] = useState(videoArray);

    useEffect(() => {
        setEverwellTutorials(videoArray)
        setCurrentVideo(everwellTutorials[0]);
    },[]);
    const handleChapterClick = useCallback((index) => {
        setCurrentVideo(everwellTutorials[index]);
    },[everwellTutorials]);

    return (
        <Slide direction="left" in={true} mountOnEnter unmountOnExit>
            <div className="w-full items-center">
                <div className="flex flex-wrap">
                    <div className="bg-gray-100 flex flex-row items-center p-3 rounded-lg w-full gap-x-2">
                        <Link to="/" className="text-gray-500 text-lg font-light">Home</Link>
                        <FaAngleRight color="#6b7280" />
                        <span className="text-lg font-medium">Everwell Tutorials</span>
                    </div>
                </div>

                <div className="grid grid-cols-3 gap-4 mt-4 border rounded-lg p-4 bg-gray-50">
                    <div className="col-span-1 text-start border-r">
                        <p className="text-3xl mt-2 font-semibold">Everwell Tutorials</p>
                    </div>

                    <div className="col-span-2">
                        <div>
                            <ReactPlayer
                                config={{
                                    file:
                                        {
                                            attributes: { controlsList: 'nodownload', disablePictureInPicture: true },
                                            tracks: [{ kind: 'subtitles', src: `/assets/subtitles/${currentVideo?.id}-en.vtt`, srcLang: 'en', default: true, mode: 'showing' }],
                                        }
                                }}
                                volume={0.5}
                                controls={true}
                                url={currentVideo?.video}
                                playing
                                height="450px"
                                width='900px'
                            />
                        </div>

                        <div className="flex flex-wrap">
                            {
                                everwellTutorials.map((chapter, index) => (
                                    <div className="w-full border rounded-lg flex items-center mt-3 bg-white p-3 gap-x-4" key={index} >
                                        <div className="flex">
                                            <span className="text-cyan-600 font-semibold text-lg py-[3px] px-[10px] rounded-md bg-cyan-50" >0{index + 1}</span>
                                        </div>
                                        <div className="w-full flex items-center gap-x-2">
                                            <span>{chapter?.title}</span>
                                            <div
                                                className="inline-block h-6 w-[1px] self-stretch bg-neutral-300 opacity-100 dark:opacity-50"></div>
                                            <span>{chapter?.duration}</span>
                                        </div>
                                        <div className="flex gap-x-2 items-center">
                                            {currentVideo?.id === chapter?.id ? (
                                                <FaPlayCircle size={30} color="#06b6d4" />
                                            ) : (
                                                <FiPlayCircle onClick={() => handleChapterClick(index)} size={30} className="cursor-pointer" color="#06b6d4" />
                                            )}

                                            {currentVideo?.id === chapter?.id ? (
                                                <p className="font-light text-sm text-gray-500">
                                                    Playing...
                                                </p>
                                            ) : (
                                                <p className="font-light text-sm text-gray-500">
                                                    Play
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Slide>
    )
}
