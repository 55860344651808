import axios from "axios";
import React from "react";
import {toast} from 'react-toastify';
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {start, stop} from "../features/appSlice";
import {Field, Form, Formik} from "formik";
import Spinner from "../components/Spinner";
import * as Yup from "yup";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const SignupSchema = Yup.object().shape({
    name: Yup.string().required('The name field is required'),
    email: Yup.string().email('Invalid email').required('The email field is required'),
    password: Yup.string()
        .min(8, 'Password should be 8 characters long...')
        .required('The password field is required'),
});

const Register = () => {
    const {isLoading} = useSelector((state) => state.app);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleSubmit = async (values) => {

        const data = {
            name: values.name,
            email: values.email,
            password: values.password,
        };

        try {
            dispatch(start());
            const res = await axios.post(
                `${process.env.REACT_APP_URL}/api/register`,
                data
            );

            dispatch(stop());
            toast.success(res?.data?.message);

            navigate('/');
        } catch (error) {
            toast.error(error?.response?.data?.error || JSON.stringify(error));
        }
    };

    return (
        <div className="background min-h-screen flex flex-col justify-between">
            <div className="container mx-auto flex justify-between items-center">
                <Link to="/">
                    <img src="/assets/everwell_logo.png" alt="logo" className="h-20 w-20 object-contain"/>
                </Link>
                <HelpOutlineIcon style={{fontSize: 40}} className="cursor-pointer"/>
            </div>
            <div className="flex justify-center items-center">
                <div className="bg-blue p-10 rounded-3xl w-auto md:w-1/3 flex flex-col gap-y-5">
                    <div className="flex flex-col space-y-2 text-center">
                        <h1 className="text-2xl text-white">
                            Create an Account
                        </h1>
                    </div>
                    <Formik
                        initialValues={{
                            name: '',
                            email: '',
                            password: '',
                        }}
                        validationSchema={SignupSchema}
                        onSubmit={values => {
                            handleSubmit(values)
                        }}
                    >
                        {({errors, touched}) => (
                            <Form className="space-y-3">
                                <div className="flex flex-col items-start">
                                    <Field name="name" type="name"
                                           className={`p-2 w-full rounded-xl  ${errors.name && touched.name ? 'border-red-600' : "border-slate-400"}`}
                                           placeholder="Name"/>
                                    {errors.name && touched.name ?
                                        <div className="text-red-500 text-sm">{errors.name}</div> : null}
                                </div>
                                <div className="flex flex-col items-start">
                                    <Field name="email" type="email"
                                           className={`p-2 w-full rounded-xl ${errors.email && touched.email ? 'border-red-600' : "border-slate-400"}`}
                                           placeholder="Email"/>
                                    {errors.email && touched.email ?
                                        <div className="text-red-500 text-sm">{errors.email}</div> : null}
                                </div>
                                <div className="flex flex-col items-start">
                                    <Field name="password" type="password"
                                           className={`p-2 w-full rounded-xl ${errors.password && touched.password ? 'border-red-600' : "border-slate-400"}`}
                                           placeholder="Password"/>
                                    {errors.password && touched.password ?
                                        <div className="text-red-500 text-sm">{errors.password}</div> : null}
                                </div>

                                {isLoading && <Spinner/>}
                                <button
                                    className='w-full bg-yellow dark:bg-slate-900 p-3 text-white font-bold rounded-xl '
                                    disabled={isLoading} type="submit">Register
                                </button>
                            </Form>
                        )}
                    </Formik>
                </div>

            </div>
            <div>© Copyright 2023 MediVista Media, LLC. All Rights Reserved.</div>
        </div>
    );
};

export default Register;
