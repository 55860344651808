import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { toast } from 'react-toastify';
import { Field, Form, Formik } from "formik";
import { Breadcrumbs, Link as MUILink, Typography } from "@mui/material";
import * as Yup from "yup";

import Spinner from "../components/Spinner";

import { logout, start, stop } from "../features/appSlice";

import { passwordReset } from "../services/authService";

const resetPasswordSchema = Yup.object().shape({
    currentPassword: Yup.string()
        .min(8, 'Current Password should be 8 characters long...')
        .required('The current password field is required'),
    newPassword: Yup
        .string()
        .required('The new password field is required')
        .min(8, 'New Password must be 8 characters long'),
    confirmPassword: Yup
        .string()
        .required('The confirm password field is required')
        .oneOf([Yup.ref('newPassword'), null], 'Must match "New Password" field value'),
});

const ResetPassword = () => {
    const { isLoading } = useSelector((state) => state.app);
    const dispatch = useDispatch();

    const handleResetPassword = async (values) => {
        const data = {
            current_password: values.currentPassword,
            new_password: values.newPassword,
            confirm_password: values.confirmPassword,
        };

        dispatch(start());
        return await passwordReset(data, dispatch)
            .then((res) => {
                if (res) {
                    toast.success("Your password has successfully updated!");
                    dispatch(stop());
                    dispatch(logout())
                }
            })
            .catch((error) => {
                dispatch(stop());
                toast.error(error?.response?.data?.message);
            });
    };

    return (
        <div className="">
            <Breadcrumbs aria-label="breadcrumb" className="mb-4">
                <MUILink underline="hover" color="inherit" href="/">
                    Home
                </MUILink>

                <Typography color="text.primary">Change Password</Typography>
            </Breadcrumbs>

            <div className="flex justify-center items-center">
                <div className="bg-blue p-10 rounded-3xl w-auto md:w-1/3 flex flex-col gap-y-5 mt-10">
                    <div className="flex flex-col space-y-2 text-center">
                        <h1 className="text-2xl tracking-tight text-white">
                            Change Password
                        </h1>
                    </div>
                    <Formik
                        initialValues={{
                            currentPassword: '',
                            newPassword: '',
                            confirmPassword: ''
                        }}
                        validationSchema={resetPasswordSchema}
                        onSubmit={values => {
                            handleResetPassword(values)
                        }}
                    >
                        {({ errors, touched }) => (
                            <Form className="space-y-3">
                                <div className="flex flex-col items-start">
                                    <Field name="currentPassword" type="password"
                                        className={`p-2 w-full rounded-xl ${errors.currentPassword && touched.currentPassword ? 'border-red-600' : "border-slate-400"}`}
                                        placeholder="Current Password" />
                                    {errors.currentPassword && touched.currentPassword ?
                                        <div className="text-red-500 text-sm">{errors.currentPassword}</div> : null}
                                </div>
                                <div className="flex flex-col items-start">
                                    <Field name="newPassword" type="password"
                                        className={`p-2 w-full rounded-xl ${errors.newPassword && touched.newPassword ? 'border-red-600' : "border-slate-400"}`}
                                        placeholder="New Password" />
                                    {errors.newPassword && touched.newPassword ?
                                        <div className="text-red-500 text-sm">{errors.newPassword}</div> : null}
                                </div>
                                <div className="flex flex-col items-start">
                                    <Field name="confirmPassword" type="password"
                                        className={`p-2 w-full rounded-xl ${errors.confirmPassword && touched.confirmPassword ? 'border-red-600' : "border-slate-400"}`}
                                        placeholder="Re-type New Password" />
                                    {errors.confirmPassword && touched.confirmPassword ?
                                        <div className="text-red-500 text-sm">{errors.confirmPassword}</div> : null}
                                </div>

                                {isLoading && <Spinner />}
                                <button
                                    className='w-full bg-yellow p-3 text-white rounded-xl font-bold' type="submit">Done
                                </button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
